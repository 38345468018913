import i18n from 'i18next'

const USER_LICENSE_MODEL = {
  QUEUE: 0,
  MANAGE: 1
}

const SUBSCRIPTION_STATUS = {
  FREE: 1,
  ACTIVE: 3,
  SUSPENDED: 4,
  CANCELED: 5
}

const SUBSCRIPTION_LICENSE_TYPE = {
  ESSENTIAL: 'Essential',
  PROFESSIONAL: 'Professional'
}

const DEFAULT_NINEBOX = {
  xLineText: i18n.t('NineBoxClassification_performance', 'PERFORMANCE'),
  yLineText: i18n.t('NineBoxClassification_potential', 'POTENTIAL'),
  xLevels: {
    low: i18n.t('NineBoxClassification_potentialLow', 'Low'),
    mid: i18n.t('NineBoxClassification_potentialModerate', 'Moderate'),
    high: i18n.t('NineBoxClassification_potentialHigh', 'High')
  },
  yLevels: {
    low: i18n.t('NineBoxClassification_potentialLow', 'Low'),
    mid: i18n.t('NineBoxClassification_potentialModerate', 'Moderate'),
    high: i18n.t('NineBoxClassification_potentialHigh', 'High')
  },
  boxes: [
    { x: 1, y: 3, text: i18n.t('NineBoxClassification_potentialGems', 'Potential Gems'), color: '#7374ad' },
    { x: 2, y: 3, text: i18n.t('NineBoxClassification_highPotentials', 'High Potentials'), color: '#444791' },
    { x: 3, y: 3, text: i18n.t('NineBoxClassification_stars', 'Stars'), color: '#444791' },
    {
      x: 1,
      y: 2,
      text: i18n.t('NineBoxClassification_inconsistentPerformers', 'Inconsistent Performers'),
      color: '#b9bad6'
    },
    { x: 2, y: 2, text: i18n.t('NineBoxClassification_corePlayers', 'Core Players'), color: '#7374ad' },
    { x: 3, y: 2, text: i18n.t('NineBoxClassification_highPerformers', 'High Performers'), color: '#444791' },
    { x: 1, y: 1, text: i18n.t('NineBoxClassification_badHires', 'Bad Hires'), color: '#b9bad6' },
    { x: 2, y: 1, text: i18n.t('NineBoxClassification_backups', 'Backups'), color: '#b9bad6' },
    { x: 3, y: 1, text: i18n.t('NineBoxClassification_workhorses', 'Trusted Professionals'), color: '#7374ad' }
  ]
}

const MONTHLY_RECOGNITION_POINT_LIMIT = 100

const FREE_TENANT_MAX_LICENSE_COUNT = 10

const MY_ORGANIZATION_SETTING_KEYS = [
  'enableNewsFeedInMyOrganizationPage',
  'enableCompanyResourcesInMyOrganizationPage',
  'enableOrganizationSearchInMyOrganizationPage',
  'enableNewHiresWidgetInMyOrganizationPage',
  'enableInternalJobBoardWidgetInMyOrganizationPage',
  'enableBirthdayOrAnniversaryWidgetInMyOrganizationPage',
  'enableVoiceOfTheEmployeeWidgetInMyOrganizationPage'
]

export {
  MY_ORGANIZATION_SETTING_KEYS,
  SUBSCRIPTION_LICENSE_TYPE,
  USER_LICENSE_MODEL,
  DEFAULT_NINEBOX,
  MONTHLY_RECOGNITION_POINT_LIMIT,
  SUBSCRIPTION_STATUS,
  FREE_TENANT_MAX_LICENSE_COUNT
}
